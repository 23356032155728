<template>
  <div class="main" id="login">
    <div class="loginPart">
      <div class="theMask">
        <div class="header">
          <h1>物联网·物有所值</h1>
        </div>
        <div class="loginBox">
          <div class="loginBox-inline">
            <div class="titleFont" v-for="(item,index) in tablist" @click="changeTab(index)" :key="index" :class="{'active': curTab == index}">{{ item }}</div>
          </div>
            <!-- <div class="titleFont">账号密码登录</div> -->
          <div class="loginCenter" v-if="curTab==0">
            <form>
              <div style="position: relative;">
                <input type="text" autocomplete="off" v-model="account" placeholder="账号" />
                <img class="names" src="../assets/loginImg/name.png" alt="">
                <!-- <div class="xian"></div> -->
              </div>
              <br />
              <div style="position: relative;margin-top: 3vh">
                <input type="password" autocomplete="off" v-model="password" placeholder="密码" />
                <img class="passW" src="../assets/loginImg/pass.png" alt="">
              </div>
              <br />
              <div style="position: relative;margin-top: 3vh">
                <input autocomplete="off" v-model="captcha" placeholder="图形验证码">
                <img slot="suffix" @click="getCaptchaImage" class="imageCode" :src="captchaImage" alt="">
                
                <img class="captchaImage" src="../assets/loginImg/captchaImage.png" alt="">
              </div>
              <br />
              <input style="cursor: pointer" type="submit" value="立即登录" @click.prevent="loginBut" />
            </form>
          </div>
          <div class="loginCenter" v-if="curTab==1">
            <form>
              <div style="position: relative;">
                <input type="text" autocomplete="off" v-model="phone" placeholder="手机号" />
                <img class="phone" src="../assets/loginImg/phone.png" alt="">
                <!-- <div class="xian"></div> -->
              </div>
              <br />
              <div style="position: relative;margin-top: 3vh">
                <input autocomplete="off"  v-model="captcha" placeholder="图形验证码">
                <img slot="suffix" @click="getCaptchaImage" class="imageCode" :src="captchaImage" alt="">

                <img class="captchaImage" src="../assets/loginImg/captchaImage.png" alt="">
              </div>
              <br />
              <div style="position: relative;margin-top: 3vh">
                <input autocomplete="off" type="text" v-model="code" placeholder="验证码">
                <div class="getCode" slot="suffix" @click="getCaptcha">{{ content }}</div>

                <img class="message" src="../assets/loginImg/message.png" alt="">
              </div>
              <br />

              <input style="cursor: pointer" type="submit" value="注册/登录" @click.prevent="loginMsg" />
            </form>
          </div>
        </div>
      </div>
    </div>
    <div id="beian" style="position: fixed;left:50%;bottom: 5px;z-index: 999;color:#000;font-size:12px; line-height:12px;margin-left:-150px;" >
      <a  href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备2023026727</a>
      <img height="12" width="12" src="~@/assets/beian.png" alt="" style="margin-left:15px">
      <a href="https://beian.mps.gov.cn/#/query/webSearch?code=37028202001161" rel="noreferrer" target="_blank">鲁公网安备37028202001161</a>
      <span @click="gly" style="color:yellow;margin-left: 10px;cursor: pointer">联系管理员</span>
    </div>
  </div>
</template>

<script>
import { setLocal } from '@/request/api/localSet.js';
import { historyRouter } from '../router';
export default {
  data() {
    return {
      tablist: ['账号密码登录','短信登录'],
      account: '',
      password: '',
      captcha:'',
      captchaImage: '',
      uuid: '',
      phone: '',
      code: "",
      msgTicket: '',
      checked: false,
      animation: {
        loginLeftLeave: "",
        LoginRightLeave: "",
      },
      curTab: 0,
      isClick: true,
      nowTime: '',
      totalTime: 0,
      content: '获取验证码',
      state: {
        time: 60,
        smsSendBtn: false,
      },
    };
  },
  created() {
    var _this = this;
    document.onkeydown = function () {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.loginBut();
      }
    };
    setInterval(() => {
        this.nowTime = this.getDateStr(0);
    }, 1000);
    this.getCaptchaImage()
  },
  mounted() {
  },
  methods: {
    changeTab(index) {
      this.curTab = index
      this.captcha = ''
      this.code = ''
      this.account = ""
      this.phone = ""
      this.password = ""
      this.getCaptchaImage()
    },
    gly() {
      this.$notify({
        title: '管理员微信',
        dangerouslyUseHTMLString: true,
        position: 'bottom-right',
        message: '<img src="http://public.half-half.cn/icon/gly.png" style="width: 200px;">'
      });
    },
    countDown() {
      this.totalTime = 60
      this.content = this.totalTime + 's后重新发送'
      let clock = window.setInterval(() => {
        this.totalTime--
        this.content = this.totalTime + 's后重新发送'
        if (this.totalTime <= 0) {
          window.clearInterval(clock)
          this.content = '重新发送验证码'
          this.totalTime = 0
            }
        },1000)
    },
  
    async getCaptcha(e) {
      e.preventDefault()
      let that = this
      if (!this.phone) return this.$message.info('请输入手机号!');
      if (!this.captcha) return this.$message.info('请输入图片验证码!');
      if (this.state.smsSendBtn) return
      this.state.smsSendBtn = true
      let interval = window.setInterval(() => {
        if (that.state.time-- <= 0) {
          that.state.time = 60
          that.state.smsSendBtn = false
          window.clearInterval(interval)
        }
      }, 1000)
      const hide = this.$message.info('验证码发送中..')
    
      const machineCode = navigator.userAgent + navigator.platform + navigator.language +  window.crypto.getRandomValues(new Uint32Array(1))[0];

      const {data: { msg:ticket } } = await this.$get('/sms/getTicketCode/' + this.phone)
      this.msgTicket = ticket
      this.$postLogin('/sms/sendVerifyCode', {
        mobile: this.phone,
        ticket,
        code: this.captcha,
        uuid: this.uuid,
        machineNo: machineCode
      })
        .then((res) => {
          if (res.data.state == 'success') {
            if (this.totalTime === 0) {
              this.totalTime = 60
              this.countDown()
              this.$message.success( `发送成功`)
            }
          } else {
            setTimeout(hide, 0)
            this.$message.error(res.data.msg)
          }
          setTimeout(hide, 500)
        })
        .catch((err) => {
          setTimeout(hide, 1)
          clearInterval(interval)
          that.state.time = 60
          that.state.smsSendBtn = false
          // this.requestFailed(err);
        })
    },
    // 生成图片验证码
    getCaptchaImage(){
      this.$get('/captchaImage').then((res=>{
        if (res.data.state == 'success') {
          this.captchaImage = 'data:image/png;base64,' + res.data.data.img
          this.uuid = res.data.data.uuid
        }
      }))
    },
    //获得当前时间
    getDateStr(AddDayCount){
        let t = new Date();
        t.setDate(t.getDate() - AddDayCount); //获取AddDayCount天前的日期
        let y = t.getFullYear();
        let m = t.getMonth() + 1;
        let d = t.getDate();
        let h = t.getHours();
        let mu = t.getMinutes();
        let s = t.getSeconds();
        return y + '-' + this.getZero(m) + '-' + this.getZero(d) + " " + this.getZero(h) + ":" + this.getZero(mu) + ':' + this.getZero(s);
    },
    //补0
    getZero(m){
        if(m < 10){
            m = '0' + m
        }
        return m;
    },
    loginBut() {
      const self = this;
      if (self.checked) {
        self.setCookie(self.account, self.password, true, 7);
      } else {
        self.clearCookie();
      }
      if (!this.account) return this.$message.info('请输入账号!');
      if (!this.password) return this.$message.info('请输入密码!');
      if (!this.captcha) return this.$message.info('请输入验证码!');
      let user = {
        userName: this.account,
        password: this.password,
        uuid: this.uuid,
        code: this.captcha
      };
      if (this.isClick) {
        this.isClick = false;
        this.$postLogin('/login', user)
          .then((res) => {
            if (res.data.state == 'success') {
              let userInfo = {
                userName: this.account,
                password: this.password,
              };
              // this.$message.success(res.data.msg);
              this.$message.success("登录成功");
              setLocal('token', res.data.data);
              window.localStorage.setItem('user', res.data.userName);
              window.localStorage.setItem('label', res.data.label);


              //console.log(res);
              setLocal('newUser', res.data);
              setLocal('userInfo', userInfo);
              this.$store.commit('setUser', res.data.userName);
              this.$store.commit('setnewUser', res.data);
              // this.userRequrst();
              setTimeout(function () {
                this.isClick = true;
                self.animation.loginLeftLeave = "leftBreak";
                self.animation.LoginRightLeave = "rightBreak";
              }, 1000);
              setTimeout(() => {
                this.$get("/largeScreenManagement/getLargeScreenManagements", {  // 用户进入大屏权限
                  page: 1,
                  size: 100
                }).then((res) => {
                  if(res.data.state == 'success'){
                    let path = res.data.datas.length>0?res.data.datas[0].path:"/cloud";
                    self.$router.push(path);
                    window.localStorage.setItem('screenRouter', path);
                  }
                })
                
              }, 1000);
              // this.userPage();
            } else {
              this.$message.info(res.data.msg);
              setTimeout(() => { this.isClick = true; }, 1000)
            }
          })
          .catch((err) => {
            //console.log(err);
            this.$message.info('登录失败，请联系管理员');
            return;
          });
      }
    },
    loginMsg(){
      const self = this;
      if (!this.phone) return this.$message.info('请输入手机号!');
      if (!this.code) return this.$message.info('请输入验证码!');
      if (!this.captcha) return this.$message.info('请输入图形验证码!');
      let user = {
        mobile: this.phone,
        verifyCode: this.code,
        ticket: this.msgTicket,
      };
      if (this.isClick) {
        this.isClick = false;
        this.$postLogin('/sms/checkAndLogin', user)
          .then((res) => {
            if (res.data.state == 'success') {
              let userInfo = {
                userName: this.phone,
              };
              // this.$message.success(res.data.msg);
              this.$message.success("登录成功");
              setLocal('token', res.data.data);
              window.localStorage.setItem('user', res.data.userName);
              //console.log(res);
              setLocal('newUser', res.data);
              setLocal('userInfo', userInfo);
              this.$store.commit('setUser', res.data.userName);
              this.$store.commit('setnewUser', res.data);
              // this.userRequrst();
              setTimeout(function () {
                this.isClick = true;
                self.animation.loginLeftLeave = "leftBreak";
                self.animation.LoginRightLeave = "rightBreak";
              }, 1000);
              setTimeout(() => {
                this.$get("/largeScreenManagement/getLargeScreenManagements", {  // 用户进入大屏权限
                  page: 1,
                  size: 100
                }).then((res) => {
                  if(res.data.state == 'success'){
                    let path = res.data.datas[0].path;
                    self.$router.push(path);
                    window.localStorage.setItem('screenRouter', path);
                  }
                })
                
              }, 1000);
              // this.userPage();
            } else {
              this.$message.info(res.data.msg);
              setTimeout(() => { this.isClick = true; }, 1000)
            }
          })
          .catch((err) => {
            //console.log(err);
            this.$message.info('登录失败，请联系管理员');
            return;
          });
      }
    },
    userRequrst() {
      let _this = this;
      let user = window.localStorage.getItem('user');
      _this.$get('/rights/getRightById', { username: user }).then((res) => {
        //console.log(res, 'userRequrst');
        if (res.data.state == 'success') {
          //console.log(res.data.datas);
          window.localStorage.setItem('userPath', JSON.stringify(res.data.datas));
          _this.$store.commit('setUserPath', res.data.datas);
          historyRouter(res.data.datas);
        }
      });
    },
    // 请求页面requrstGet('/rights/getRightById', { username: roleName.userName })
    // userPage() {
    //   let _this = this;
    //   this.$get('/rights/getRightById', { username: 'admin' }).then((res) => {
    //     //console.log(res);
    //     setLocal('userPage', res.data.datas);
    //     _this.$store.commit('setUserPage', res.data.datas);
    //   });
    // },
    // 存cookie
    setCookie(c_name, c_pwd, c_status, exdays) {
      var exdate = new Date();
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays);
      window.document.cookie = 'userName' + '=' + c_name + ';path=/;expires=' + exdate.toGMTString();
      window.document.cookie = 'userPwd' + '=' + c_pwd + ';path=/;expires=' + exdate.toGMTString();
      window.document.cookie = 'status' + '=' + c_status + ';path=/;expires=' + exdate.toGMTString();
    },
    //取值赋值cookie
    getCookie: function () {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split('; ');
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split('=');
          if (arr2[0] == 'userName') {
            this.account = arr2[1];
          } else if (arr2[0] == 'userPwd') {
            this.password = arr2[1];
          } else if (arr2[0] == 'status') {
            if (arr2[1] == 'true') {
              this.checked = true;
            } else {
              this.checked = false;
            }
          }
        }
      }
    },
    //清除cookie
    clearCookie: function () {
      this.setCookie('', '', -1);
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.main_body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.title {
  width: 100%;
  text-align: center;
  font-size: 4vh;
}

@widthCommon: 19vw;

.login {
  text-align: center;
  width: @widthCommon;
  background: #0080ff;
  height: 5vh;
  line-height: 5vh;
  margin-top: 2vh;
  cursor: pointer;
}

.common {
  //   background: rgba(16, 84, 173, 0.6);
  background: url('~@/assets/others/commonBg.png') no-repeat;
  background-size: 100% 100%;
  width: @widthCommon;
  height: 5.5vh;
  position: relative;
  margin-top: 2vh;
  padding: 0 1vw 0 1vw;
  box-sizing: border-box;

  img {
    position: absolute;
    top: 50%;
    right: 1vw;
    transform: translate(0, -50%);
    max-width: 1.1vw;
    max-height: 3vh;
  }
}

input {
  outline: none;
  height: 5vh;
  border: none;
  background: transparent;
  color: #fff;
  padding-left: 0.5vw;
}

.userInput {
  //   display: inline-block;
  display: flex;
  height: 5vh;
  margin-top: 1px;
  line-height: 5vh;
  width: 16vw;

  input {
    flex: 1;
  }
}

.flexBody {
  display: flex;
  justify-content: space-between;
  margin-top: 1vh;
  align-items: center;
  width: 19vw;
}

.flexBody /deep/ .el-checkbox__label {
  color: #fff;
}

.flexBody div {
  font-size: 1.3vh;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: #ededed !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  background-color: transparent !important;
  background-image: none;
  transition: background-color 50000s ease-in-out 0s;
  color: #fff !important;
}
</style>
<style>
/* 公共部分 */
* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  /* 去除默认的下划线 */
  outline: none;
  /* 去除旧版浏览器的点击后的外虚线框 */
  color: #fff;
}

#login {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.outBorder {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url("../assets/index/outBorder.png") no-repeat center;
  background-size: 100% 100%;
  overflow: hidden;
}

/* 登录页面部分 */
.theMask {
  width: 100vw;
  height: 100vh;
  background:url("../../src/assets/loginImg/bgNew.jpg") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}

.loginContant {
  position: relative;
  filter: drop-shadow(0 0 25px rgba(0, 156, 255, 1));
}

.loginLeft {
  position: absolute;
  top: 31.5vh;
  left: 0;
  width: 56vw;
  height: 37vh;
  background: url("../assets/index/leftLogin.png") no-repeat center;
  background-size: 100% 100%;
  font-family: "BLACK";
  font-size: 4vh;
  color: #fff;
  line-height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginRight {
  position: absolute;
  top: 24.5vh;
  right: 0;
  width: 46.2vw;
  height: 51vh;
  background: url("../assets/index/rightLogin.png") no-repeat center;
  background-size: 100% 100%;
}

.loginRight form {
  margin-top: 16.5vh;
  margin-left: 3vw;
  width: 20vw;
  height: 18vh;
  display: flex;
  flex-direction: column;
}

.loginRight input {
  width: 15vw;
  height: 5vh;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #ffffff;
  border-radius: 4px;
  padding: 0 0.5vw;
  color: #fff;
  
}

.loginRight input:focus {
  outline: none;
  background: rgba(0, 156, 255, 0.2);
  border: 1px solid #009cff;
  border-radius: 4px;
}

.loginRight input:last-child {
  border: none;
  background-color: rgba(0, 156, 255, 0.7);
  width: 16vw;
  
}

.leftBreak {
  /* 定义动画 */
  animation-duration: 1s;
  /* 动画时间 */
  animation-name: leftfade;
  /* 关键帧名字 */
}

.rightBreak {
  /* 定义动画 */
  animation-duration: 1s;
  /* 动画时间 */
  animation-name: rightfade;
  /* 关键帧名字 */
}

/* 定义关键帧 */
@keyframes leftfade {
  0% {
    opacity: 1;
    transform: translateX(0px);
  }

  20% {
    opacity: 0.8;
    transform: translateX(-30px);
  }

  100% {
    opacity: 0;
    transform: translateX(-600px);
  }
}

@keyframes rightfade {
  0% {
    opacity: 1;
    transform: translateX(0px);
  }

  20% {
    opacity: 0.8;
    transform: translateX(30px);
  }

  100% {
    opacity: 0;
    transform: translateX(600px);
  }
}

.header{
  width: 75%;
  height: 14vh;

  /* background-size: 100% 100%; */
  margin: 10vh auto 0;
  position: relative;
}
.header h1{
  text-align: center;
  font-size: 3vw;
  line-height: 11vh;
  font-family: 'Title';
  background: linear-gradient(to bottom, #fff 30%, #B8F9FD 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.loginBox{
  width: 32vw;
  height: 55vh;
  background: url("../../src/assets/loginImg/bgBox.png") no-repeat;
  background-size: 100% 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  overflow: hidden;
}
.loginBox-inline{
  width: 80%;
  margin: auto;
  padding-bottom: 15px;
  /* border-bottom:1px solid #ccc ; */
  cursor: pointer;
  display: flex;
    text-align: center;
  >div{
    position: relative;
    width:50;
  }
}
.loginBox-inline>div:nth-child(1).active::after{
    position: absolute;
    content: ' ';
    width:40%;
    height: 3px;
    bottom: -15px;
    left: 30%;
    background-color: #2AAFC8;
}
.loginBox-inline>div:nth-child(2).active::after{
    position: absolute;
    content: ' ';
    width:40%;
    height: 3px;
    bottom: -15px;
    left: 30%;
    background-color: #2AAFC8;
}
.titleFont{
    width: 100%;
    text-align: center;
    font-family: 'PANG';
    background: linear-gradient(to top, #3CA0CE, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3vh;
    margin-top: 2.5vh;
}
.loginCenter{
  width: 80%;
  margin: 3vh auto 0;
}
.loginCenter form {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.loginCenter input {
  background: #042A35;
  border: 1px solid #207186;
  border-radius: 2px;
  color: #fff;
  font-size: 1.8vh;
  padding-left: 3vw;
  width: 90%;
  height: 6vh;
  border-radius: 40px;
}

.loginCenter input:focus {
  outline: none;
  /* background: rgba(0, 156, 255, 0.2); */
  border: 1px solid #009cff;
}

.loginCenter input:last-child {
  border: none;
  width: 20vw;
  height: 6vh;
  line-height: 6vh;
  font-style: italic;
  margin: 1vh auto 0;
  background: url("../../src/assets/loginImg/buttonNew.png") no-repeat;
  background-size: 100% 100%;
  font-size: 2.5vh;
  font-family: 'PANG';
  padding: 0;
}
/* .loginCenter .xian{
  position: absolute;
  top: 1vh;
  left: 2.5vw;
  width: 1px;
  height: 2.8vh;
  background: #fff;
} */
.loginCenter .names{
  position: absolute;
  left: 1vw;
  top: 1.5vh;
  width: 1.2vw;
  height: 2.8vh;
}
.loginCenter .passW{
  position: absolute;
  left: 1vw;
  top: 1.5vh;
  width: 1.2vw;
  height: 2.8vh;
}
.loginCenter .phone{
  position: absolute;
  left: 1vw;
  top: 1.5vh;
  width: 1.3vw;
}
.loginCenter .message{
  position: absolute;
  left: 1vw;
  top: 1.5vh;
  width: 1.3vw;
}
.captchaImage{
  position: absolute;
  left: 1vw;
  top: 1.5vh;
  width: 1.3vw;
}
.imageCode{
  position: absolute;
  right: 1vw;
  top: 1.2vh;
  width: 5vw;
}
.getCode{
  cursor: pointer;
  position: absolute;
  color: #30F7FA;
  right: 1.2vw;
  top:2.3vh;
}
input::placeholder{
  color: #fff;
  font-size: 2vh;
}
.bottomBox{
  position: absolute;
  bottom: 0;
  width: 60%;
  height: 8vh;
  background: url("../../src/assets/loginImg/time.png") no-repeat;
  background-size: 100% 100%;
  left: 50%;
  transform: translate(-50%, 0); 
}
.bottomBox h1{
  text-align: center;
  font-size: 2.8vh;
  line-height: 8vh;
  font-family: 'PANG';
  font-weight: 400;
  color: #fff;
  margin-right: 1vw;
}

</style>
