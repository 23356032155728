import Vue from 'vue'
import Vuex from 'vuex'
import { getLocal } from '@/request/api/localSet.js';
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userMsg: window.localStorage.getItem('token') || {},
        userPath: JSON.parse(window.localStorage.getItem('userPath')) || {},
        user: window.localStorage.getItem('user') || '',
        newUser: getLocal('newUser') || '',
        screenTitle: ''
    },
    mutations: {
        setUserPath(state, data) {
            state.userPath = data;
        },
        setUser(state, data) {
            state.user = data;
        },
        setnewUser(state, data) {
            state.newUser = data;
        },
        
        
    },
    actions: {},
    modules: {},
})