import axios from 'axios'
import { Message } from 'element-ui'
import router from '../router/index'
const request = axios.create({
    withCredentials: true,
    timeout: 500000,
})
const upImg = axios.create({
    withCredentials: true,
})


request.interceptors.request.use(
    config => {
        if (window.localStorage.getItem('token')) {
            const token = window.localStorage.getItem('token').replace(/"/g, "");
            config.headers.token = token;
        }
        return config
    },
    error => {
        return Promise.error(error)
    }
)
request.interceptors.response.use(
    response => {
        if (response.data.state == 505) {
            const token = window.localStorage.getItem('user');
            if (token) {
                Message.info('登录超时,请重新登录')
            }
            router.push('/login');
            window.localStorage.clear();
        }
        return response
    },
    error => {
        // Message.info('服务器连接失败！')
        console.error('服务器连接失败！')
        return Promise.reject(error)
    }
)
export {
    request,
    upImg,
}