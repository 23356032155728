<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { historyRouter } from './router';

export default {
  data() {
    return {};
  },
  watch: {
    $route(to) {
      if (to.name == 'login') {
        window.localStorage.clear();
      }
    },
  },
  created() {
    this.$store.commit('setUserPath', JSON.parse(window.localStorage.getItem('userPath')));
    let cacheData = this.$store.state.userPath;
    historyRouter(cacheData);
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
 html,body ,#app{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #c1c1c1;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  /* box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
  /* background: #ededed; */
  background: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border: 1px solid transparent;
}
.amap-logo {
  /* 去掉高德地图logo */
  display: none;
  opacity: 0 !important;
}
.amap-copyright {
  /* 去掉高德的版本号 */
  opacity: 0;
}
/* tooltip背景 */
.atooltip {
  background-color: rgba(3, 3, 3, 0.7) !important;
}
.atooltip.el-tooltip__popper[x-placement^='top'] .popper__arrow {
  border-top-color: rgba(3, 3, 3, 0.7);
}
.atooltip.el-tooltip__popper[x-placement^='top'] .popper__arrow:after {
  border-top-color: rgba(3, 3, 3, 0.7);
}
 .el-table::before{
    width: 0!important;
}
</style>
