import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import login from "../views/home.vue";
import map from "../views/bigScreen/dalixian/map.vue";
import routing from "../utils/routing";
// 导入消息显示组件
import { Message } from "element-ui";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
    component: login,
  },
  {
    path: "/login",
    component: login,
  },
  {
    path: "/map",
    component: map,
  },
  {
    path: '/productH5',
    component: () =>
        import('../views/bigScreen/h5/H5.vue'),
  },
  {
    path: '/viewPage',
    component: () =>
        import('../views/bigScreen/h5/viewPage.vue'),
  },
// 导航页
  {
    path: "/zizhouNav",
    name: "zizhouNav",
    component: () => import("../views/nav/zizhouNav.vue"),

  },

  // 手机验证默认首页
  {
    path: "/cloud",
    name: "cloud",
    component: () => import("../views/bigScreen/cloud/index.vue"),//无jx

  },
  {
    path: "/zizhou",
    name: "zizhou",
    component: () => import("../views/bigScreen/zizhou/index.vue"),

  },
  {
    path: '/firstScreen',
    name: "firstScreen",
    component: () =>
        import('../views/bigScreen/firstScreen.vue'),
  },
  // 默认首页-显示报警记录
  {
    path: "/cloud_trigger",
    name: "/cloud_trigger",
    component: () => import("../views/bigScreen/cloud/index_trigger.vue"),//无jx

  },

  // 汉台首屏
  {
    path: "/hantai",
    name: "hantai",
    component: () => import("../views/bigScreen/hantai/index.vue"),//建大仁科

  },
  // 汉台首屏
  {
    path: "/hanzhong",
    name: "hanzhong",
    component: () => import("../views/bigScreen/hanzhong/index.vue"),//建大仁科

  },
  // 吉药农业首屏
  {
    path: "/jiyaonongye",
    name: "jiyaonongye",
    component: () => import("../views/bigScreen/jiyaonongye/index.vue"),//无jx

  },
  //无人机大屏
  {
    path: "/wrj",
    name: "wrj",
    component: () => import("../views/bigScreen/wrj/index.vue"),

  },
  {
    path:'/agroTrace',
    name: "agroTrace",
    component: () => import("../views/bigScreen/cloud/agroTrace.vue"),
  },
  {
    path:'/insectScreen',
    name: "insectScreen",
    component: () => import("../views/bigScreen/cloud/insectScreen.vue"),
  },
  {
    path:'/soilScreen',
    name: "soilScreen",
    component: () => import("../views/bigScreen/cloud/soilScreen.vue"),
  },
  {
    path:'/weatherScreen',
    name: "weatherScreen",
    component: () => import("../views/bigScreen/cloud/weatherScreen.vue"),
  },
  {
    path:'/jxweatherScreen',
    name: "jxweatherScreen",
    component: () => import("../views/bigScreen/jx/jxweatherScreen.vue"),
  },
  {
    path:'/jxsoilScreen',
    name: "jxsoilScreen",
    component: () => import("../views/bigScreen/jx/jxsoilScreen.vue"),
  },
  {
    path:'/waterScreen',
    name: "waterScreen",
    component: () => import("../views/bigScreen/cloud/waterScreen.vue"),
  },
  {
    path:'/irrigateControl',
    name: "irrigateControl",
    component: () => import("../views/bigScreen/cloud/control/irrigateControl.vue"),
  },
  // 登陆后首页
  {
    path: "/dalixian",
    name: "dalixian",
    component: () => import("../views/bigScreen/dalixian/index.vue"),
  },
  {
    path: "/zhenping",
    name: "zhenping",
    component: () => import("../views/bigScreen/zhenping/index.vue"),
  },
    // 登陆后首页
    {
        path: "/zonghe",
        name: "zonghe",
        component: () => import("../views/bigScreen/zonghe/index.vue"),//jx数据接口
    },
  // 登陆后首页
  {
    path: "/lvkang",
    name: "lvkang",
    component: () => import("../views/bigScreen/jx/lvkang.vue"),//jx数据接口
  },
  // 登陆后首页
  {
    path: "/niuwuzhen",
    name: "niuwuzhen",
    component: () => import("../views/bigScreen/niuwuzhen/index_trigger.vue"),////无jx
  },
  // 登陆后首页
  {
    path: "/jiaodao",
    name: "jiaodao",
    component: () => import("../views/bigScreen/jiaodaozhen/index_trigger.vue"),//无jx
  },
  // sixianzhen
  {
    path: "/sixianzhen",
    name: "sixianzhen",
    component: () => import("../views/bigScreen/sixianzhen/index.vue"),//无jx
  },
  // 监控大屏
  {
    path: "/monitScreen",
    name: "monitScreen",
    component: () => import("../views/bigScreen/dalixian/monitScreen_dalixian.vue"),
  },
  // 监控大屏
  {
    path: "/monitScreen_dalixian",
    name: "monitScreen_dalixian",
    component: () => import("../views/bigScreen/dalixian/monitScreen_dalixian.vue"),
  },

  // 养殖溯源
  {
    path: "/breed",
    name: "breed",
    component: () => import("../views/bigScreen/hantai/agroTrace.vue"),
  },
  // 新溯源大屏1028
  {
    path: "/suyuan",
    name: "suyuan",
    component: () => import("../views/bigScreen/suyuan.vue"),
  },
  // 灌溉控制
  {
    path: "/zonghe/irrigate",
    name: "zonghe/irrigate",
    component: () => import("../views/bigScreen/zonghe/control/irrigateControl.vue"),
  },
  // 气象大屏
  {
    path: '/zonghe/weatherScreen',
    name: 'zonghe/weatherScreen',
    component: () => import ('../views/bigScreen/zonghe/weatherScreen.vue'),
  },
  // 土壤大屏
  {
    path: '/zonghe/soilScreen',
    name: 'zonghe/soilScreen',
    component: () => import ('../views/bigScreen/zonghe/soilScreen.vue'),
  },
  // 虫情大屏
  {
    path: '/zonghe/insectScreen',
    name: 'zonghe/insectScreen',
    component: () =>
        import ('../views/bigScreen/zonghe/insectScreen.vue'),
  },
// 党建大屏
  {
    path: '/partyBuilding',
    name: 'partyBuilding',
    component: () =>
        import ('../views/bigScreen/partyBuilding/index.vue'),
  },
  // 冷库大屏
  {
    path: '/coldStorage',
    name: 'coldStorage',
    component: () =>
        import ('../views/bigScreen/coldStorage/index.vue'),
  },
  // 后台
  {
    path: "/backHome",
    name: "backHome",
    component: () => import("../views/backHome.vue"),
    children: [
    ],
  },
];
let dafoer = {
  path: "*",
  name: "404",
  component: () => import("../views/404.vue"),
};
const router = new VueRouter({
  // mode:'history',
  routes,
});
let mergeRoute = [];
export function historyRouter(routingArray) {
  // 递归耗费资源
  // routingArray.forEach((v) => {
  //     if (v.children) {
  //         v.children.forEach(m => {
  //             if (m.path && m.path != '') {
  //                 mergeRoute.push(routing[m.path.replace('/', '')])
  //             } else {
  //                 historyRouter(m.children)
  //             }
  //         })
  //     } else {
  //         if (v.path && v.path != '') {
  //             mergeRoute.push(routing[v.path.replace('/', '')])
  //         }
  //     }
  // })
  if (routingArray && routingArray.length > 0) {
    routingArray.forEach((v) => {
      if (v.children) {
        v.children.forEach((m) => {
          if (m.path && m.path != "")
            mergeRoute.push(
              routing[m.path.replace("/", "")]
                ? routing[m.path.replace("/", "")]
                : dafoer
            );
          else {
            if (m.children) {
              m.children.forEach((p) => {
                if (p.path && p.path != "")
                  mergeRoute.push(
                    routing[p.path.replace("/", "")]
                      ? routing[p.path.replace("/", "")]
                      : dafoer
                  );
              });
            }
          }
        });
      }
    });
  }
  routes.forEach((oldPath) => {
    if (oldPath.name == "backHome") {
      mergeRoute.forEach((el) => {
        oldPath.children.push(el);
      });
    }
  });
  routes.push(dafoer);
  router.addRoutes(routes);
  const originalPush = VueRouter.prototype.push;
  VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
  };
}
// 路由守卫

router.beforeEach((to, from, next) => {
  let token = window.localStorage.getItem("token");
  if (to.path == "/login" || token) {
    next();
  } else if (to.path == '/productH5' || '/viewPage') {
    next()
  } else {
    Message.error("请先登录");
    setTimeout(() => {
      next({
        path: "/login",
      });
    }, 500);
  }
});
router.afterEach(() => {
  NProgress.done();
});

export default router;
