import { Loading } from 'element-ui'
let loading = null;

function startLoading(text) {
    loading = Loading.service({
        lock: true,
        text: text || '加载中……',
        background: 'rgba(0, 0, 0, 0.7)'
    })
}

function endLoading() {
    loading.close()
}
export { startLoading, endLoading }