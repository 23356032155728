// 存储数据
export const setLocal = (name, data) => {
        window.localStorage.setItem(name, JSON.stringify(data));
    }
    // 获取数据
export const getLocal = (name) => {
        if (window.localStorage.getItem(name)) {
            return JSON.parse(window.localStorage.getItem(name));
        }
    }
    // 删除数据
export const delLocal = (name) => {
    window.localStorage.removeItem(name);
}