import { request, upImg } from '../index.js'
import qs from 'qs'
export const requrstPut = function (url, data) {
    return request({
        method: 'PUT',
        url: '/aps' + url,
        data: qs.stringify(data),
        headers: {'content-type': 'application/x-www-form-urlencoded'},
    })
}
export const requrstPost = function (url, data) {
    return request({
        method: 'POST',
        url: '/aps' + url,
        data: qs.stringify(data),
        headers: {'content-type': 'application/x-www-form-urlencoded'},
    })
}
export const requrstPostLogin = function (url, data) {
    return request({
        method: 'POST',
        url: '/aps' + url,
        data,
        headers: {'content-type': 'application/json',},
    })
}
export const requrstDel = function(url, data) {
    return request({
        method: 'DELETE',
        url: '/aps' + url,
        data: qs.stringify(data),
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    })
}
export const requrstPostJson = function(url, data) {
    return request({
        method: 'POST',
        url: '/aps' + url,
        data,
        headers: { 
            'content-type': 'application/json',
            'Authorization': window.localStorage.getItem('user'),
            'token': window.localStorage.getItem('token').replace(/"/g, "")
        },
    })
}
export const requrstGetJson = function(url, data) {
    return request({
        method: 'GET',
        url: '/aps' + url,
        data,
        headers: { 
            'content-type': 'application/json',
            'Authorization': window.localStorage.getItem('user'),
            'token': window.localStorage.getItem('token').replace(/"/g, "")
        },
    })
}
export const requrstGui = function(url, data) {
    return request({
        method: 'POST',
        url: '/gui' + url,
        data: data,
        headers: { 'content-type': 'text/plain' },
    })
}
export const requrstGet = function(url, data) {
    return request({
        method: 'GET',
        url: '/aps' + url,
        params: data,
        headers: { Authorization: window.localStorage.getItem('user') },
    })
}
export const requrstGetBlob = function(url, data) {
    return request({
        method: 'GET',
        url: '/aps' + url,
        responseType:"blob",
        params: data,
        headers: { Authorization: window.localStorage.getItem('user') },
    })
}
export const logisticGet = function(url, data) {
    return request({
        method: 'GET',
        url: '/logistic' + url,
        params: data,
        headers: { Authorization: window.localStorage.getItem('user') },
    })
}
export const upImgs = function(url, data) {
    return upImg({
        method: 'POST',
        url: url,
        data: data
    })
}

export const userServer = function(url, data) {
    return upImg({
        method: 'POST',
        url: '/userServer' + url,
        data: data,
    })
}