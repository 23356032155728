<template>
    <div>
        <el-select
              v-model="selectValue"
              class="base-select"
              :popper-append-to-body="false"
              @change="handleSelectChange"
            >
              <el-option
                class="option"
                v-for="(item,i) in optionList"
                :key="i"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
    </div>
</template>
<script>
export default{
    props:{
        value: {
          type: [String, Number],
          default: ''
        },
        optionList:{
            type:Array,
             default: []
        }
    },
    data(){
      return{
        selectValue: this.value
      }
    },
    watch: {
      selectValue(newValue) {
        this.$emit('input', newValue);
      },
      value(newValue) {
        this.selectValue = newValue;
      }
    },
    methods:{
      handleSelectChange(v){
        this.$emit('onSelectChange', v)
      }
    }

}
</script>
<style lang="scss" scoped>
.base-select {
  position: absolute;
  width: 40%;
  top: 5px;
  right: 10px;
  margin-right: 10px;
  /deep/ .el-input__inner {
    background-color: rgba(14, 51, 59, 0.5);
    border: 1px solid #113f44;
    color: #fff;
    font-size: 14px;
  }
  /deep/.el-select-dropdown {
    background-color: #0e333b !important;
  }
  /deep/.el-popper[x-placement^="bottom"] .popper__arrow::after {
    border-bottom-color: #0e333b;
  }
  .el-select-dropdown__item {
    padding-left: 10px;
  }
  .el-select-dropdown__item.hover {
    background-color: #0e2325;
  }
}
</style>